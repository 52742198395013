<template>
  <b-row>
    <b-row class="p-2">
      <b-col v-if="!doc.id" md="12">
        <b-row>
          <b-col md="12">
            <b-form-group label="Тип документа">
              <b-form-select
                v-model="doc.document_type"
                :options="docTypes"
                text-field="display_name"
                value-field="value"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Номер паспорта">
              <b-form-input v-model="doc.document_number" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Дата выдачи">
              <b-form-input v-model="doc.issue_date" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Кем выдан">
              <b-form-input v-model="doc.issued_by" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Срок действия">
              <b-form-input v-model="doc.valid_till" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Адрес прописки">
              <b-form-textarea v-model="doc.assigned_address" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-checkbox v-model="doc.is_identified" inline switch>
              Идентифицирован?
            </b-form-checkbox>
          </b-col>
          <b-col class="mt-2" md="12">
            <b-button variant="primary" @click="docCreate">Добавить</b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="doc.id">
        <b-row>
          <b-col>
            <h3>Фото документа</h3>
          </b-col>
          <b-col class="text-right">
            <b-form-file
              id="docFileUpload"
              v-model="docPhoto"
              class="d-none"
              plain
              @input="uploadDoc"
            />
            <b-button variant="primary" @click="showUpload">
              Загрузить
            </b-button>
          </b-col>
        </b-row>
        <b-carousel
          v-if="doc.doc_photos.length"
          class="mt-2"
          controls
          indicators
        >
          <template v-for="photo in doc.doc_photos">
            <b-carousel-slide :key="photo.id" :img-src="photo.photo" />
          </template>
        </b-carousel>
        <b-button class="mt-4" variant="primary" @click="onAddDoc">
          Добавить
        </b-button>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
export default {
  name: 'ClientCreateDoc',
  data() {
    return {
      docTypes: [],
      doc: {
        doc_photos: [],
        user: this.$route.params.id,
      },
      docPhoto: null,
      loading: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'document_type_display',
          label: 'Тип документа',
        },
        {
          key: 'document_number',
          label: 'Номер документа',
        },
        {
          key: 'valid_till',
          label: 'Срок действия',
        },
        {
          key: 'is_identified',
          label: 'Идентифицирован',
        },
      ],
    }
  },
  created() {
    this.getDocOptions()
  },
  methods: {
    showUpload() {
      document.getElementById('docFileUpload').click()
    },
    async getDocs() {
      this.loading = true
      const res = await this.$http.get(`admin/docs/?user=${this.doc.user}`)
      const docs = res.data.results
      if (this.doc.id) {
        const docIndex = docs.findIndex((item) => item.id === this.doc.id)
        this.doc = docs[docIndex]
      }
      this.loading = false
    },
    async uploadDoc() {
      try {
        const formData = new FormData()
        formData.append('photo_type', 4)
        formData.append('document', this.doc.id)
        formData.append('photo', this.docPhoto)
        await this.$http({
          method: 'POST',
          url: 'docs/photo/',
          data: formData,
          config: { headers: { 'content-type': 'multipart/form-data' } },
        })
        await this.getDocs()
        this.$toast.success('Загружено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
    async getDocOptions() {
      const res = await this.$http.options('admin/docs/')
      this.docTypes = res.data.actions.POST.document_type.choices
    },
    async docCreate() {
      try {
        const res = await this.$http.post(`admin/docs/`, this.doc)
        this.doc = res.data
        this.$toast.success('Добавлено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
    onAddDoc() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss"></style>
